import request from "@/routes/api/api_conextion";

export const getBankIntegrationWidgetUrl = params =>
  request({
    url: `/integracion/bancaria`,
    method: "post",
    data: params
  });

export const getUnnaxFaceIdUrl = params =>
  request({
    url: `/faceid`,
    method: "post",
    data: params
  });
